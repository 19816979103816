import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFetch } from '../../utils/apiRequest';
import Swal from 'sweetalert2';
import icon from '../../assets/upou.png'
import uri from '../../uri';



/* This component is for the login page */
export default function LoginPage() {
    const [userToken,setUserToken] = useState("");
    const [userPrivileges, setUserPrivileges] = useState("");
    
    const navigate = useNavigate();

    // Callback for google SSO
    async function handleCallbackResponse(response) {
        
        localStorage.setItem("userToken", response.credential);
        var res = await getFetch(uri.backend + '/validate');

        // Check if user authentication is not successful
        if (!res) {
            localStorage.setItem('userToken',"");
            localStorage.setItem('userPrivileges', '');
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'User is not authorized to access the website.',
            });
        }

        if (res.success) {
            localStorage.setItem('userPrivileges',res.privilege);
        } else {
            localStorage.setItem('userToken',"");
            localStorage.setItem('userPrivileges','');
        }

        setUserToken(localStorage.getItem('userToken'));
        setUserPrivileges(localStorage.getItem('userPrivileges'));

        // Navigate to dashboard
        navigate('/in/dashboard');
    }

    // Checking the validity of the token
    async function checkToken(){
        var res = await getFetch(uri.backend + '/validate');
        
        // ID token no longer valid
        if (!res) {
            localStorage.setItem('userToken',"");
            localStorage.setItem('userPrivileges','');            
            // Reload app
            window.location.reload(false); 
        }
    };

    useEffect(() => {
         
        /* global google */
        google.accounts.id.initialize({
            client_id: "49142684035-d29edp914pbdptuhsi78n8snvl95akdj.apps.googleusercontent.com",
            callback: handleCallbackResponse
        });

        if (localStorage.getItem('userToken') && localStorage.getItem('userPrivileges')) {
            checkToken();
            setUserToken(localStorage.getItem('userToken'));
            setUserPrivileges(localStorage.getItem('userPrivileges'));
	        navigate('/in/dashboard');
        }

        // Google Sign-in Button
        google.accounts.id.renderButton(
            document.getElementById("signInDiv"),
            { type: "standard", theme: "outline", size: "large", shape: "rectangular", width: "350", logo_alignment: "left" }
        )

     }, []);

     return (
        <div className='h-screen grid justify-items-center items-center font-montserrat font-medium text-sidebar-text'>
            <div className='w-auto md:w-1/2 2xl:w-1/3'>
                {/* Contents */}
                <div className='py-[3vh] px-8 bg-primary-red shadow-xl rounded-xl'>
                    {/* UPOU Seal */}
                    <div className='flex justify-center'>
                        <img
                            className="object-center w-[50%]"
                            src={icon}
                            alt="UPOU Seal"
                        />
                    </div>
                    {/* Description */}
                    <h2 className='text-lg lg:text-2xl text-highlight text-center mt-[4%]'>
                        Welcome to the UPOU-ICTDO<br />Device Inventory & Auditing System (DIAS)
                    </h2>
                    {/* Login Button */}
                    <div className='flex justify-center align-middle items-center'>
                        <button id="signInDiv" className="mt-[4%]"/>
                    </div>
                </div>
            </div>
        </div>
    );
};
