import { useState, useEffect } from 'react';
import { CSVLink } from "react-csv";
import { MenuAlt2Icon } from '@heroicons/react/solid';
import exportIcon from '../../assets/export.svg';
import PrimaryButton from '../../components/buttons/PrimaryBtn';

/* Components */
import AddItemModal from './modals/AddItemModal';
import Filter from '../../components/table/Filter';
import InventoryTable from './InventoryTable';
import SearchBar from '../../components/table/Search';
import Pagination from '../../components/table/Pagination';

/* Utility Functions */
import { getFetch } from '../../utils/apiRequest';
import uri from './../../uri';



/**
 * This component is the page for the inventory where users can browse the stored items.
 */
const Inventory = () => {

    // State handler for current inventory items in the database
    const [currentData, setCurrentData] = useState([]);

    // State handler for export data
    const [exportData, setExportData] = useState([]);
    // State handler for pagination
    const [currentPage, setCurrentPage] = useState(1);
    // State handler for total pages
    const [totalPages, setTotalPages] = useState(0);
    // State handler for search value
    const [searchTerm, setSearchTerm] = useState('');
    // State handler and initial values for parameters for http requests
    const [params, setParams] = useState({
        page: 0,
        sendpages: true,
    })

    // Map data into csv fields
    const csv_headers = [
        {label: "Device Name", key:"deviceName"},
        {label: "Status", key:"status"},
        {label: "Category", key:"category"},
        {label: "MAC Address", key:"MACAddress"},
        {label: "Purchase Date", key:"purchaseDate"},
        {label: "Repletion Date", key:"repletionDate"},
        {label: "Serial Number", key:"serialNumber"},
        {label: "Vendor", key:"vendor"},
        {label: "Building", key:"building"},
        {label: "Exact Location", key:"exactLocation"},
        {label: "Current User", key:"currentUser"},
        {label: "Current Office", key:"office"},
        {label: "Notes", key:"notes"},
    ]

    // Function used to fetch data from database and set display.
    async function getInventory() {
        var data = await getFetch(uri.backend + '/inventory/', params);
        if(data.pages){
            setTotalPages(data.pages);
        }
        await setDisplay(data.data);
    };

    // Function that will handle data export 
    async function exportInventory(){
        var exportParams = params;
        exportParams.export = "true";

        // Fetch data
        var data = await getFetch(uri.backend + '/inventory/', exportParams);
        exportParams.export = "false";

        let inventory = [];

        if (data.data.length > 0) {
            // Map out the entries returned by fetch
            data.data.forEach((item, index) => {
                inventory.unshift({
                    _id: item._id,
                    deviceName: item.deviceName,
                    status: item.status,
                    category: item.category,
                    MACAddress: item.MACAddress,
                    purchaseDate: new Date(item.purchaseDate*1000).toLocaleDateString("en-US"),
                    repletionDate: new Date(item.purchaseDate*1000).toLocaleDateString("en-US"),
                    serialNumber: item.serialNumber,
                    vendor: item.vendor,
                    building: item.building,
                    exactLocation: item.exactLocation,
                    office: item.office,
                    currentUser: item.currentUser,
                    notes: item.notes,
                });
            });
            setExportData([...inventory]);
        }        
    }

    // This function is used to update UI state.
    async function setDisplay(data) {
        let inventory = [];

        // Checking if the inventory collections in DB is empty.
        if (data.length > 0) {

            // Map out the entries returned by fetch.
            data.forEach((item, index) => {
                inventory.unshift({
                    _id: item._id,
                    deviceName: item.deviceName,
                    status: item.status,
                    category: item.category,
                    MACAddress: item.MACAddress,
                    purchaseDate: item.purchaseDate,
                    repletionDate: item.repletionDate,
                    serialNumber: item.serialNumber,
                    vendor: item.vendor,
                    building: item.building,
                    exactLocation: item.exactLocation,
                    office: item.office,
                    currentUser: item.currentUser,
                    notes: item.notes,
                });
            });
            // Setting UI table state
            setCurrentData([...inventory]);

        } else {
            setCurrentData([]);
        }
    }

    // Fetch data when you change pages
    const paginate = async (pageNumber) => {
        setCurrentPage(pageNumber);
        
        // Set query parameters
        setParams({
            page: pageNumber-1,
            sendpages: true
        });
    };

    // Function to handle search
    const handleSearch = event => {
        // Prevent page refresh upon form submission
        event.preventDefault();

        // Set query parameters
        setParams((prevState) => {
            return({
                ...prevState,
                deviceName: searchTerm
            });
        });
    };
    

    // Run this function on component mount
    useEffect(() => {
        // Select first page in pagination
        paginate(1);
    }, []);


    // Fetch inventory if 'params' state has been updated
    useEffect(() => {
        getInventory();
    }, [params]);


    // Triggers a download when there is data to be downloaded
    useEffect(() => {
        if (exportData.length > 0) document.getElementById("exporter").click();
    }, [exportData]);


    return (
        <div className="bg-gray-50 min-h-screen w-full lg:w-[87vw] lg:absolute lg:top-0 lg:right-0">

            {/* Page Title */}
            <div className="px-[4vw] lg:px-[2.25vw] pt-[3vw] sm:pt-[1.5vw] flex text-lg sm:text-2.5xl fhd:text-[1.75vw] font-montserrat font-bold">
                <span>Browse Inventory</span>
                <MenuAlt2Icon className="hidden lg:block lg:w-7 fhd:w-[1vw] ml-[0.5vw]" />
            </div>

            {/* Divider */}
            <hr className='mt-3 lg:mt-4 mb-4 border-1 border-gray-400' />
            
            <div className="px-[2.5vw]">
                {/* Filter */}
                <div className="float-left absolute w-fit z-10">
                    <Filter
                        params={params}
                        setParams={setParams}
                    />
                </div>
                <div className="lg:flex ml-12 sm:ml-14 2xl:ml-[3.4vw]">
                    {/* Search Bar */}
                    <form onSubmit={handleSearch}>
                        <SearchBar
                            searchValue={searchTerm}
                            changeHandler={(e) => { setSearchTerm(e.target.value) }}
                            placeholder="Search by device name..."
                            helpText={true}
                        />
                    </form>
                    <div className="flex mt-4 lg:mt-0 -ml-12 sm:-ml-14 lg:ml-auto justify-between lg:space-x-4">
                        {/* Add Item Button */}
                        <AddItemModal
                            data={currentData}
                            setData={setCurrentData}
                        />

                        {/* Export Button */}                       
                        <PrimaryButton handleClick={exportInventory} textSize="text-xs sm:text-sm 2xl:text-[0.95vw] h-8 lg:h-10 xl:h-12 fhd:h-[4.3vh]">
                            <img className="inline-flex w-5 2xl:w-[1vw] mr-2 sm:mr-3 px-1 sm:px-0 2xl:py-3 sm:py-1 fill" src={exportIcon} alt="Export Icon"/>
                            Export Data
                        </PrimaryButton>
                        <CSVLink className="hidden" id="exporter" enclosingCharacter={``} data={exportData} headers={csv_headers} filename="Inventory Export" target="_blank"> </CSVLink>
                    </div>
                </div>
            </div>

            {/* Inventory Items Table */}
            <div className='mt-[3vw] px-[1.5vw] lg:mt-0'>
                <InventoryTable
                    data={currentData}
                    setData={setCurrentData}
                    params={params}
                    setParams={setParams}
                    getInventory={getInventory}
                />
            </div>

            {/* Pagination */}
            <div className="pr-[2.5vw] mt-4 float-right">
                <Pagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    paginate={paginate}
                />
            </div>
        </div>
    );
};


export default Inventory;
