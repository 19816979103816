/*
    URI.js
    Contains Uniform Resource Identifier used for the web app
    Replace the contents of this file when redeploying the website under different IP/Domain
    URI Example:
    Backend: http://localhost:3001
    Frontend: http://localhost:3000
*/

exports.frontend =  "https://dias.upou.edu.ph"
exports.backend = "https://dias.upou.edu.ph/api"
