import { ExclamationCircleIcon } from '@heroicons/react/outline';
import ViewItemModal from '../../inventory/modals/ViewItemModal';

/**
 * This component is used for displaying necessary data/details for the notifications component.
 * Props:
 *   icon   --- {boolean} indicates whether the icons should be displayed or not
 *   data   --- {object} contains information about the item to be displayed
 */ 
const NotifDetails = ({ icon, data }) => {

    return (
        <div className="inline-flex space-x-4 w-full">
            {/* Icon to be displayed */}
            {icon === true ? (
                <ExclamationCircleIcon className="w-8 2xl:w-[4.5vh] text-[#dda25a]"/>
            ) : (
                <></>
            )}

            {/* Item Detail */}
            <span className="flex flex-wrap lg:pt-1 text-sm sm:text-base 2xl:text-[0.95vw] font-inter font-normal">
                {/* Make only the device name clickable to show item details */}
                {/* Wrapping the device name in span tag to prevent line break */}
                <span><ViewItemModal style={`cursor-pointer`} data={data}>
                    <b><u>{data.deviceName}</u></b>
                </ViewItemModal></span>

                &nbsp;with serial number&nbsp;<b>{data.serialNumber}</b>&nbsp;is set for repletion on&nbsp;<b>{new Date(data.repletionDate * 1000).toLocaleDateString("en-US")}.</b>
            </span>
        </div>
    );
};


export default NotifDetails;