
import { useState, useEffect } from 'react';
import { FormikProvider, useFormik, Form } from 'formik';

/* Components */
import PrimaryButton from '../buttons/PrimaryBtn';
import Dropdown from '../forms/DropdownSelect1'

/* Importing options to be used for the dropdown selects */
import {
    buildingOptions,
    mainCategoryOptions,
    subcategoryOptions,
    statusOptions,
    locationOptions,
    groupedOfficeOptions
} from '../../utils/objectProperties'



/**
 * This component is used to display all the contents of the filter options popover.
 */
const FilterList = ({ setParams }) => {

    // State handler for the main category selected
    const [mainCategory, setMainCategory] = useState('');
    // State handler for the filtered options for subcategory
    const [subcategory, setSubcategory] = useState([]);
    // State handler for the building selected
    const [building, setBuilding] = useState('');
    // State handler for the filtered options for exact locations
    const [locations, setLocations] = useState('');

    // delay/timeout
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    
    // Formik setup for input fields
    const inputSetup = useFormik({
        // initial values for the fields
        initialValues: {
            status: '',
            category: '',
            subcategory: '',
            building: '',
            exactLocation: '',
            office: ''
        },
        // submit handler
        onSubmit: async (values) => {
            await sleep(300);

            // pass submitted values to DB
            setParams((prevState) => {
                return({
                     ...prevState,
                    status: values.status,
                    category: values.subcategory,
                    building: values.building,
                    exactLocation: values.exactLocation,
                    office: values.office
             });
        })
    }})

    // Filter out the options for subcategories based on the main category selected
    useEffect(() => {
        let temp = [];
        // Filter all subcategories that match the main category
        subcategoryOptions.filter((obj) =>{
            if (obj.link.includes(mainCategory) && mainCategory) {
                return temp.unshift(obj);   // Add matching data to temp array
            }
            return null;
        })
        setSubcategory(temp);
    }, [mainCategory]);


    // Filter out the options for exact locations based on the building selected
    useEffect(() => {
        let temp = [];
        // Filter all locations that match the building selected
        locationOptions.filter((obj) => {
            if (obj.link.includes(building) && building) {
                return temp.unshift(obj);   // Add matching data to temp array
            }
            return null;
        })
        setLocations(temp);
    }, [building]);

    
    return (
        <div className="bg-white px-6 pt-3 pb-14 flex-grow rounded-lg shadow-lg ring-1 ring-black ring-opacity-20">
            <span className="text-red-500 break-words text-sm 2xl:text-[0.85vw]"><i>To view all items again after applying filters, apply filters once again with all input fields empty.</i></span>
            <FormikProvider value={inputSetup}>
                <Form>
                    {/* Status */}
                    <Dropdown
                        dropdownStyle="mt-2 grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw]"
                        options={statusOptions}
                        label="Status"
                        value={inputSetup.values.status}
                        onChange={value => {
                            inputSetup.setFieldValue('status', value.value)                          
                        }}
                    />
                    {/* Category */}
                    <Dropdown
                        label="Category"
                        dropdownStyle="grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw]"
                        options={mainCategoryOptions}
                        onChange={value => {
                            setSubcategory([]);
                            setMainCategory(value.value);
                            inputSetup.setFieldValue('category', value.value);
                            inputSetup.setFieldValue('subcategory', '');
                        }}
                        value={inputSetup.values.category}
                    />
                    {/* Subcategory */}
                    <Dropdown
                        // key={mainCategory}
                        // isDisabled={(mainCategory === "Scanners")}   // Disable if main category is "Scanners"
                        label="Subcategory"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw]"
                        dropdownStyle="grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        options={subcategory}
                        onChange={value => {
                            inputSetup.setFieldValue('subcategory', value.value)
                        }}
                        value={inputSetup.values.subcategory}
                    />
                    {/* Help text if a main category is selected */}
                    {inputSetup.values.category && !inputSetup.values.subcategory ?
                        <div className="grid lg:grid-cols-3 pb-2">
                            <div></div>                             {/* Empty container to not ruin styling */}
                            <i className="text-red-600 col-span-2 text-xs lg:text-sm">Selecting a proper subcategory is required.</i>
                        </div>
                        : <></>
                    }
                    {/* Building */}
                    <Dropdown
                        dropdownStyle="grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw]"
                        options={buildingOptions}
                        label="Building"
                        value={inputSetup.values.building}
                        onChange={value => {
                            setLocations([]);
                            setBuilding(value.value);
                            inputSetup.setFieldValue('building', value.value);
                            inputSetup.setFieldValue('exactLocation', '');
                        }}
                    />
                    {/* Exact Location */}
                    <Dropdown
                        key={building}
                        dropdownStyle="grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw] mr-5"
                        options={locations}
                        label="Exact Location"
                        value={inputSetup.values.exactLocation}
                        onChange={value => {
                            inputSetup.setFieldValue('exactLocation', value.value);
                        }}
                    />
                    {/* Help text if a building is selected */}
                    {inputSetup.values.building && !inputSetup.values.exactLocation ?
                        <div className="grid lg:grid-cols-3 pb-2">
                            <div></div>                             {/* Empty container to not ruin styling */}
                            <i className="text-red-600 col-span-2 text-xs lg:text-sm">Selecting an exact location is required.</i>
                        </div>
                        : <></>
                    }
                    {/* Current Office */}
                    <Dropdown
                        dropdownStyle="grid lg:grid-cols-3"
                        className="font-inter col-span-2 text-sm sm:text-base 2xl:text-[1vw]"
                        labelStyle="text-sm sm:text-base 2xl:text-[1vw]"
                        options={groupedOfficeOptions}
                        label="Current Office"
                        value={inputSetup.values.office}
                        onChange={value => {
                            inputSetup.setFieldValue('office', value.value);
                        }}
                    />
                    {/* Apply Filters Button */}
                    <div className="float-right mt-4">
                        <PrimaryButton
                            type="submit"
                            textSize="text-sm"
                            isDisabled={
                                inputSetup.values.subcategory === "" && inputSetup.values.category !== ""
                                || inputSetup.values.exactLocation === "" && inputSetup.values.building !== ""
                            }
                        >
                            Apply Filters
                        </PrimaryButton>
                    </div>
                </Form>
            </FormikProvider>
        </div>
    );
};


export default FilterList;