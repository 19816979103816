import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import { SearchIcon } from '@heroicons/react/solid';
import { useState } from 'react';


/**
 * This component contains a search bar.
 */
const SearchBar = ({
    searchValue,        // {string} state that will handle the value entered in the input
    changeHandler,      // {function} will be used to handle onChange event
    helpText,           // {boolean} indicates whether helptext should be shown or not
    ...props            // will catch any other props
}) => {
    
    // State handler for help text to appear
    const [isFocused, setIsFocused] = useState(false);

    return (
        <>
            <div className="flex space-x-1 z-30">
                {/* Input field */}
                <input
                    className="block w-full lg:w-auto px-4 lg:px-5 2xl:px-[1vw] py-2 2xl:py-3 text-sm sm:text-base 2xl:text-[1vw] h-9 lg:h-10 xl:h-12 fhd:h-[4.3vh] 
                        font-inter text-white bg-gray-200 border rounded-xl focus:text-dark-blue focus:bg-white
                        focus:border-light-blue focus:ring-dark-blue focus:outline-none focus:ring focus:ring-opacity-40"
                    name="search"
                    type="text"
                    value={searchValue}
                    onChange={changeHandler}
                    onFocus={() => setIsFocused(true)}
                    onBlur={() => setIsFocused(false)}
                    {...props}
                />

                {/* Wrapping the search icon with a tooltip */}
                <Tippy content={<span>Search</span>}>
                    <button className="px-4 2xl:px-[1vw] text-dark-blue bg-light-blue rounded-xl h-9 lg:h-10 xl:h-12 fhd:h-[4.3vh]">
                        <SearchIcon className="w-5 sm:w-6 2xl:w-[1.5vw] rounded cursor-pointer" />
                    </button>
                </Tippy>

                {/* Help text for larger viewports */}
                { helpText ?
                    <div className="hidden xl:flex">
                        { isFocused ?
                            <span className=" text-red-500 break-words text-sm 2xl:text-[0.85vw]"><i>To view all items again after searching an item,<br />press for search again with an empty input.</i></span>
                            : <></>
                        }
                    </div>
                    : <></>
                }
            </div>

            { helpText ? 
                <>  {/* Helptext for normal viewports */}
                    <div className="hidden lg:flex xl:hidden">
                    { isFocused ?
                        <span className=" text-red-500 break-words text-sm 2xl:text-[0.85vw]"><i>To view all items again after searching an item,<br/>press for search again with an empty input.</i></span>
                        : <></>
                    }
                    </div>

                    {/* Helptext for smaller viewports */}
                    <div className="lg:hidden">
                        { isFocused ?
                            <span className=" text-red-500 break-words text-sm 2xl:text-[0.85vw]"><i>To view all items again after searching an item, press for search again with an empty input.</i></span>
                            : <></>
                        }
                    </div>
                </> : <></>
            }
        </>
    );
};


export default SearchBar;