import { useState, useEffect } from 'react';
import { ChartSquareBarIcon } from '@heroicons/react/outline';
import uri from './../../../uri';

/* Components */
import PieChart from './PieChart';

/* Utility Functions */
import {getFetch} from '../../../utils/apiRequest';


/* This component displays general statistics regarding the items in the inventory. */
const Statistics = () => {

    // State handlers for data needed
    const [statusLabel, setStatusLabel] = useState([]);
    const [statusData, setStatusData] = useState([]);
    const [categoryLabel, setCategoryLabel] = useState([]);
    const [categoryData, setCategoryData] = useState([]);
    const [total,setTotal] = useState(0);

    // Function that will fetch status report
    async function fetchStatus() {
        var body = await getFetch(uri.backend + '/inventory/report/status')
        if (body !== undefined) {
            setStatusData([parseInt(body[0].count), parseInt(body[1].count), parseInt(body[2].count), parseInt(body[3].count)]);
            setStatusLabel([body[0].name,body[1].name,body[2].name,body[3].name]);
            setTotal([parseInt(body[0].count)+parseInt(body[1].count)+parseInt(body[2].count)+parseInt(body[3].count)]);
        }
    }

    // Function that will fetch categories report
    async function fetchCategories() {
        var body = await getFetch(uri.backend + '/inventory/report/categories')
        if (body !== undefined){
	        setCategoryLabel([body[0].name,body[1].name,body[2].name,body[3].name,body[4].name]);
            setCategoryData([parseInt(body[0].count),parseInt(body[1].count),parseInt(body[2].count),parseInt(body[3].count),parseInt(body[4].count)]); 
        }
    }

    // Fetching data upon component mount
    useEffect(() => {
        fetchCategories();
        fetchStatus();
    }, [])


    return (
        <>
            {/* Heading and description */}
            <div className="w-full justify-between inline-flex text-[4.75vw] sm:text-xl xl:text-[1.5vw] font-montserrat font-semibold text-dark-blue">
                <div className="flex space-x-1 sm:space-x-3 items-center">
                    <ChartSquareBarIcon className="w-[6vw] sm:w-8 lg:w-[4vh]" />
                    <span>General Statistics</span>
                </div>

            </div>
            <p className="ml-[3vw] sm:ml-12 fhd:ml-[3.25vw] mt-[2vw] sm:my-3 lg:mt-[2vh] text-sm sm:text-base 2xl:text-[0.95vw] font-inter">
                There are <u><b>{total}</b></u> items currently stored in the inventory system.
            </p>

            {/* Statistics */}
            <div className="block lg:flex mx-[5vw] my-[5.5vw] sm:my-8 lg:my-[4vh] text-[4vw] sm:text-xl xl:text-[1.25vw] font-montserrat font-semibold">
                
                {/* Inventory Status */}
                <div className="basis-1/2 space-y-4">
                    <span>Status Summary</span>
                    {total > 0 ? 
                        <div className="flex">
                            {/* Pie Chart */}
                            <div className="w-2/5 pl-[4vw] lg:pl-[1.5vw]">
                                <PieChart
                                    labels={statusLabel}
                                    chartData={statusData}
                                />
                            </div>

                            {/* Summary */}
                            <div className="pl-[7vw] lg:pl-[1.5vw] my-auto font-inter font-normal text-sm sm:text-base 2xl:text-[0.95vw] fhd:space-y-[0.65vw]">
                                <p><b>{statusData[0]}</b> {statusLabel[0]} Items</p>
                                <p><b>{statusData[1]}</b> {statusLabel[1]} Items</p>
                                <p><b>{statusData[2]}</b> {statusLabel[2]} Items</p>
                                <p><b>{statusData[3]}</b> {statusLabel[3]} Items</p>
                            </div>
                        </div>
                        :
                        <div className="h-40 flex items-center justify-center text-base font-inter font-normal">
                            Empty inventory
                        </div>
                    }
                </div>

                {/* Item Category Summary */}
                <div className="mt-[7vw] sm:mt-8 lg:mt-0 basis-1/2 space-y-4">
                    <span>Item Category Summary</span>
                    {total > 0 ?
                        <div className="flex">
                            {/* Pie Chart */}
                            <div className="w-2/5 pl-[4vw] lg:pl-[1.5vw]">
                                <PieChart
                                    labels={categoryLabel}
                                    chartData={categoryData}
                                />
                            </div>

                            {/* Summary */}
                            <div className="pl-[7vw] lg:pl-[1.5vw] my-auto font-inter font-normal text-sm sm:text-base 2xl:text-[0.95vw] fhd:space-y-[0.65vw]">
                                <p><b>{categoryData[0]}</b> {categoryLabel[0]}</p>
                                <p><b>{categoryData[1]}</b> {categoryLabel[1]}</p>
                                <p><b>{categoryData[2]}</b> {categoryLabel[2]}</p>
                                <p><b>{categoryData[3]}</b> {categoryLabel[3]}</p>
                                <p><b>{categoryData[4]}</b> {categoryLabel[4]}</p>
                            </div>
                        </div>
                        :
                        <div className="h-40 flex items-center justify-center text-base font-inter font-normal">
                            Empty inventory
                        </div>
                    }
                </div>
            </div>
        </>
    );
};


export default Statistics;
