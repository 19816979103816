import { MemoryRouter as Router, Routes, Route } from 'react-router-dom';
import './assets/fonts.css';
import 'tailwindcss/tailwind.css';

/* Pages */
import Layout from './Layout';
import LoginPage from './pages/login-page/LoginPage';
import Dashboard from './pages/dashboard/Dashboard';
import Inventory from './pages/inventory/Inventory';
import UserControlPage from './pages/user-control/UserControlPage';
import CreateItemPage from './pages/inventory/CreateItemPage';



/* Main function */
export default function App() {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<LoginPage />} />
                <Route path="/in/" element={<Layout />} >
                    <Route exact path="/in/dashboard" element={<Dashboard />} />
                    <Route exact path="/in/inventory" element={<Inventory />} />
                    <Route exact path="/in/inventory/new" element={<CreateItemPage />} />
                    <Route exact path="/in/user-control" element={<UserControlPage />} />
                </Route>
            </Routes>
        </Router>
    );
};